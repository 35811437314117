import 'bootstrap';
import AOS from 'aos';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { sendContactEmail } from './sendEmail.js';

// Initialize AOS
AOS.init({
    duration: 1000,
    once: true,
    offset: 100
});

// Initialize i18next
i18next
    .use(LanguageDetector)
    .init({
        debug: true,
        fallbackLng: 'en',
        resources: {
            en: {
                translation: {
                    services: "Services",
                    menus: "Menus",
                    specialties: "Specialties",
                    contact: "Contact",
                    heroTitle: "Exclusive Private Chef Experience",
                    heroSubtitle: "Bringing luxury dining to your villa in St Barth",
                    bookNow: "Book Your Experience",
                    servicesTitle: "Our Services",
                    villaExperience: "Villa Experience",
                    villaExperienceDesc: "Complete private chef service in your villa, from breakfast to dinner",
                    yachtService: "Yacht Service",
                    yachtServiceDesc: "Exclusive chef service aboard your yacht, including provisioning",
                    provisioning: "Provisioning",
                    provisioningDesc: "Villa and yacht provisioning with premium local products",
                    eventsService: "Events & Cocktails",
                    eventsServiceDesc: "Luxury cocktails and catering for special events",
                    cookingClasses: "Cooking Classes",
                    cookingClassesDesc: "Private Caribbean and French cuisine classes",
                    beachPicnics: "Beach Picnics",
                    beachPicnicsDesc: "Luxury picnic experiences at Colombier or Shell Beach",
                    testimonialsTitle: "Guest Testimonials",
                    testimonial1: "\"Exceptional service. The chef created an unforgettable dinner on our yacht with amazing local ingredients.\"",
                    client1: "- Jean-Pierre M., Paris",
                    testimonial2: "\"The picnic at Colombier beach was absolutely magical. Every detail was perfect, from the champagne to the fresh lobster.\"",
                    client2: "- Elizabeth R., New York",
                    testimonial3: "\"A fully stocked villa upon arrival and a personal chef for the week made our St Barth vacation truly luxurious.\"",
                    client3: "- Michael S., London",
                    eventsTitle: "Special Events and Partnerships",
                    holidayTitle: "Holiday Season",
                    holidayDesc: "Exclusive Christmas and New Year dinners with special menus",
                    bucketTitle: "Bucket Regatta",
                    bucketDesc: "Specialized catering for crews and guests during the prestigious sailing regatta",
                    rumTitle: "Rum Tasting",
                    rumDesc: "Tasting of Caribbean rum with gourmet snacks",
                    villaAgencyTitle: "Villa Agencies",
                    villaAgencyDesc: "Preferred partner of leading agencies renting luxury villas in St Barth",
                    conciergeTitle: "Concierge Service",
                    conciergeDesc: "Excellent cooperation with top concierge services on the island",
                    yachtBrokerTitle: "Yacht Brokers",
                    yachtBrokerDesc: "Reliable culinary partner of leading chartered companies",
                    dietaryTitle: "Special Dietary Requirements",
                    dietaryLead: "We are proud to accommodate all dietary preferences and requirements, ensuring an exceptional culinary experience for each guest",
                    vegetarianTitle: "Vegetarian & Vegan Menu",
                    vegetarianDesc: "Creative plant-based dishes using fresh local products with innovative cooking techniques",
                    glutenFreeTitle: "Gluten-Free",
                    glutenFreeDesc: "Delicious gluten-free alternatives without compromising taste and presentation",
                    kosherTitle: "Kosher Menu",
                    kosherDesc: "Respectful preparation of kosher dishes following all traditional requirements",
                    allergyTitle: "Food Allergies",
                    allergyDesc: "Special attention to allergies and prevention of cross-contamination",
                    dietaryNote: "Please inform us of any dietary requirements or allergies when booking. We will gladly adapt our menus to your needs",
                    contactTitle: "Book Now",
                    contactLead: "Contact us to create your unforgettable culinary experience in St Barth",
                    name: "Your Name",
                    email: "Your Email",
                    phone: "Phone/WhatsApp",
                    villaName: "Villa/Yacht Name",
                    selectService: "Select Service Type",
                    villaService: "Villa Service",
                    yachtService: "Yacht Service",
                    eventService: "Special Event",
                    picnicService: "Beach Picnic",
                    classService: "Cooking Class",
                    arrivalDate: "MM/DD/YYYY",
                    guests: "Number of Guests",
                    message: "Tell us about your desired experience, dietary requirements, or special requests",
                    send: "Send Request",
                    directContact: "Direct Contact",
                    availableHours: "Available Hours",
                    availableHoursText: "Available 7/7 - 24/24",
                    flexibleScheduling: "Flexible Scheduling",
                    footerTagline: "Luxury Private Chef Experience in St Barth",
                    aboutTitle: "About Your Chef",
                    aboutLead: "With international experience in prestigious establishments, Chef Sylvain Serouart brings his gastronomic expertise directly to your villa in St Barth.",
                    aboutText1: "After honing his culinary art in Michelin-starred restaurants in France, Chef Sylvain traveled the world, enriching his flavor palette and mastery of international cuisines.",
                    aboutText2: "His journey has taken him from prestigious Parisian kitchens to luxury establishments in the Caribbean, including significant experiences as a private chef for an exclusive clientele.",
                    aboutText3: "Now based in St Barth, he offers refined cuisine that elegantly combines Mediterranean flavors, local Caribbean products, and French haute cuisine techniques.",
                    aboutText4: "His culinary philosophy is based on product excellence, respect for seasonality, and constantly renewed creativity to create unique and personalized gastronomic experiences.",
                    menusTitle: "Our Menus",
                    breakfast: "Breakfast",
                    lunch: "Lunch",
                    dinner: "Dinner",
                    continentalTitle: "Continental Breakfast",
                    continentalDesc: "Fresh pastries, tropical fruits, yogurt with muesli",
                    healthyTitle: "Healthy Breakfast",
                    healthyDesc: "Açai bowl, avocado toast, fresh smoothies",
                    mediterraneanTitle: "Mediterranean Lunch",
                    mediterraneanDesc: "Fresh fish, grilled vegetables, quinoa salad",
                    gourmetToGoTitle: "Gourmet To Go",
                    gourmetToGoDesc: "Luxury beach picnic boxes",
                    caribbeanSeafoodTitle: "Caribbean Seafood",
                    caribbeanSeafoodDesc: "Local lobster, ceviche, fresh island flavors",
                    lightFreshTitle: "Light & Fresh",
                    lightFreshDesc: "Colorful salads, grilled items, fresh-pressed juices",
                    signatureTitle: "Signature Dinner",
                    signatureDesc: "Seven-course tasting menu with wine pairing",
                    oceanTableTitle: "Ocean to Table",
                    oceanTableDesc: "Fresh seafood of your choice",
                    caribbeanLuxuryTitle: "Caribbean Luxury",
                    caribbeanLuxuryDesc: "Grilled lobster, truffle risotto, champagne sauce",
                    sunsetBBQTitle: "Sunset BBQ",
                    sunsetBBQDesc: "Grilled specialties with stunning ocean views",
                    specialtiesTitle: "Culinary Specialties",
                    detoxTitle: "Detox & Wellness",
                    detoxDesc: "Personalized nutrition programs, detox juices, wellness menus",
                    vipBreakfastTitle: "VIP Breakfast",
                    vipBreakfastDesc: "Luxury breakfast on the terrace with sea views",
                    bbqTitle: "BBQ & Plancha",
                    bbqDesc: "Luxury seaside grilling: lobster, wagyu, premium fish",
                    celebrationsTitle: "Celebrations",
                    celebrationsDesc: "Birthdays, proposals, private events",
                    welcomePackTitle: "Welcome Pack",
                    welcomePackDesc: "Champagne, fresh fruits, gourmet snacks upon arrival",
                    photographyTitle: "Food Photography",
                    photographyDesc: "Photo session of your gastronomic experiences",
                    marketTourTitle: "Market Tour",
                    marketTourDesc: "Guided tour to the best local producers",
                    giftCardsTitle: "Gift Cards",
                    giftCardsDesc: "Give the gift of a unique gourmet experience",
                    galleryTitle: "Our Creations",
                    chefTableTitle: "Chef's Table",
                    chefTableDesc: "Exclusive gastronomic experience with personalized tasting menu",
                    beachDiningTitle: "Beach Dining",
                    beachDiningDesc: "Unique culinary experience at sunset on your private beach",
                    seaEscapeTitle: "Sea & Gastronomy Escape",
                    seaEscapeLead: "Experience the ultimate luxury: A sunset dinner cruise aboard a magnificent Lagoon 55 catamaran",
                    seaEscapeSunset: "Sail into the sunset in the pristine waters of St Barth",
                    seaEscapeService: "Private chef and hostess at your service",
                    seaEscapeLocation: "Anchor in the most exclusive cove of the island",
                    seaEscapeDining: "Gourmet dining under the stars",
                    seaEscapeBook: "Book Your Sea Escape"
                }
            },
            fr: {
                translation: {
                    services: "Services",
                    menus: "Menus",
                    specialties: "Spécialités",
                    contact: "Contact",
                    heroTitle: "Expérience Chef Privé Exclusive",
                    heroSubtitle: "Une cuisine raffinée dans votre villa à St Barth",
                    bookNow: "Réserver votre expérience",
                    servicesTitle: "Nos Services",
                    villaExperience: "Service en Villa",
                    villaExperienceDesc: "Service de chef privé complet dans votre villa, du petit-déjeuner au dîner",
                    yachtService: "Service Yacht",
                    yachtServiceDesc: "Service de chef exclusif à bord de votre yacht, incluant l'approvisionnement",
                    provisioning: "Approvisionnement",
                    provisioningDesc: "Approvisionnement de villas et yachts en produits locaux premium",
                    eventsService: "Événements & Cocktails",
                    eventsServiceDesc: "Cocktails de luxe et service traiteur pour événements spéciaux",
                    cookingClasses: "Cours de Cuisine",
                    cookingClassesDesc: "Cours privés de cuisine caribéenne et française",
                    beachPicnics: "Pique-niques",
                    beachPicnicsDesc: "Expériences pique-nique de luxe à Colombier ou Shell Beach",
                    testimonialsTitle: "Témoignages",
                    testimonial1: "\"Service exceptionnel. Le chef a créé un dîner inoubliable sur notre yacht avec des produits locaux extraordinaires.\"",
                    client1: "- Jean-Pierre M., Paris",
                    testimonial2: "\"Le pique-nique à la plage de Colombier était absolument magique. Chaque détail était parfait, du champagne au homard frais.\"",
                    client2: "- Elizabeth R., New York",
                    testimonial3: "\"Une villa entièrement approvisionnée à l'arrivée et un chef privé pour la semaine ont rendu nos vacances à St Barth vraiment luxueuses.\"",
                    client3: "- Michael S., Londres",
                    aboutTitle: "À Propos de Votre Chef",
                    aboutLead: "Fort d'une expérience internationale dans des établissements prestigieux, le Chef Sylvain Serouart apporte son savoir-faire gastronomique directement dans votre villa à St Barth.",
                    aboutText1: "Après avoir affiné son art culinaire dans des restaurants étoilés en France, le Chef Sylvain a parcouru le monde, enrichissant sa palette gustative et sa maîtrise des cuisines internationales.",
                    aboutText2: "Son parcours l'a mené des prestigieuses cuisines parisiennes aux établissements de luxe des Caraïbes, en passant par des expériences marquantes en tant que chef privé pour une clientèle exclusive.",
                    aboutText3: "Installé à St Barth, il propose aujourd'hui une cuisine raffinée qui marie avec élégance les saveurs méditerranéennes, les produits locaux des Caraïbes et les techniques de la haute gastronomie française.",
                    aboutText4: "Sa philosophie culinaire repose sur l'excellence des produits, le respect des saisons et une créativité sans cesse renouvelée pour créer des expériences gastronomiques uniques et personnalisées.",
                    menusTitle: "Nos Menus",
                    breakfast: "Petit-déjeuner",
                    lunch: "Déjeuner",
                    dinner: "Dîner",
                    continentalTitle: "Petit-déjeuner Continental",
                    continentalDesc: "Viennoiseries fraîches, fruits tropicaux, yaourt avec muesli",
                    healthyTitle: "Petit-déjeuner Healthy",
                    healthyDesc: "Bowl d'açai, toast à l'avocado, smoothies frais",
                    mediterraneanTitle: "Déjeuner Méditerranéen",
                    mediterraneanDesc: "Poisson frais, légumes grillés, salade de quinoa",
                    gourmetToGoTitle: "Gourmet à Emporter",
                    gourmetToGoDesc: "Boîtes pique-nique luxe pour la plage",
                    caribbeanSeafoodTitle: "Fruits de Mer Caraïbes",
                    caribbeanSeafoodDesc: "Langouste locale, ceviche, saveurs insulaires fraîches",
                    lightFreshTitle: "Léger & Frais",
                    lightFreshDesc: "Salades colorées, grillades, jus pressés",
                    signatureTitle: "Dîner Signature",
                    signatureDesc: "Menu dégustation sept plats avec accord vins",
                    oceanTableTitle: "De l'Océan à la Table",
                    oceanTableDesc: "Fruits de mer frais selon votre choix",
                    caribbeanLuxuryTitle: "Luxe Caraïbe",
                    caribbeanLuxuryDesc: "Grilled lobster, truffle risotto, champagne sauce",
                    sunsetBBQTitle: "BBQ au Coucher du Soleil",
                    sunsetBBQDesc: "Spécialités grillées avec vue imprenable sur l'océan",
                    specialtiesTitle: "Nos Spécialités",
                    detoxTitle: "Détox & Bien-être",
                    detoxDesc: "Programmes nutritionnels personnalisés, jus détox, menus bien-être",
                    vipBreakfastTitle: "Petit-déjeuner VIP",
                    vipBreakfastDesc: "Petit-déjeuner luxueux sur la terrasse avec vue mer",
                    bbqTitle: "BBQ & Plancha",
                    bbqDesc: "Grillades de luxe en bord de mer : langouste, wagyu, poissons premium",
                    celebrationsTitle: "Célébrations",
                    celebrationsDesc: "Anniversaires, demandes en mariage, événements privés",
                    welcomePackTitle: "Pack de Bienvenue",
                    welcomePackDesc: "Champagne, fresh fruits, amuse-bouches gourmets à l'arrivée",
                    photographyTitle: "Photographie Culinaire",
                    photographyDesc: "Séance photo de vos expériences gastronomiques",
                    marketTourTitle: "Tour du Marché",
                    marketTourDesc: "Visite guidée chez les meilleurs producteurs locaux",
                    giftCardsTitle: "Cartes Cadeaux",
                    giftCardsDesc: "Offrez une expérience gastronomique unique",
                    galleryTitle: "Notre Galerie",
                    eventsTitle: "Événements Spéciaux et Partenariats",
                    holidayTitle: "Saison des Fêtes",
                    holidayDesc: "Dîners exclusifs de Noël et Nouvel An avec menus spéciaux",
                    bucketTitle: "Bucket Regatta",
                    bucketDesc: "Service traiteur spécialisé pour les équipages et invités pendant la prestigieuse régate",
                    rumTitle: "Dégustation de Rhum",
                    rumDesc: "Dégustation de rhums caraïbes avec amuse-bouches raffinés",
                    villaAgencyTitle: "Agences de Villas",
                    villaAgencyDesc: "Partenaire privilégié des principales agences de location de villas de luxe à St Barth",
                    conciergeTitle: "Service Conciergerie",
                    conciergeDesc: "Collaboration parfaite avec les meilleurs services de conciergerie de l'île",
                    yachtBrokerTitle: "Courtiers Yacht",
                    yachtBrokerDesc: "Partenaire culinaire de confiance des principales sociétés de charter",
                    dietaryTitle: "Régimes Spéciaux",
                    dietaryLead: "Nous sommes fiers de prendre en compte toutes les préférences et exigences alimentaires, garantissant une expérience culinaire exceptionnelle à chaque convive",
                    vegetarianTitle: "Menu Végétarien et Vegan",
                    vegetarianDesc: "Plats végétaux créatifs à base de produits locaux frais avec des techniques de cuisine innovantes",
                    glutenFreeTitle: "Sans Gluten",
                    glutenFreeDesc: "Délicieuses alternatives sans gluten sans compromis sur le goût et la présentation",
                    kosherTitle: "Menu Kasher",
                    kosherDesc: "Préparation respectueuse des plats kasher selon toutes les exigences traditionnelles",
                    allergyTitle: "Allergies Alimentaires",
                    allergyDesc: "Attention particulière aux allergies et prévention des contaminations croisées",
                    dietaryNote: "Veuillez nous informer de vos exigences alimentaires ou allergies lors de la réservation. Nous adaptons volontiers nos menus à vos besoins",
                    contactTitle: "Réserver",
                    contactLead: "Contactez-nous pour créer votre expérience culinaire parfaite à St Barth",
                    name: "Votre Nom",
                    email: "Votre Email",
                    phone: "Téléphone/WhatsApp",
                    villaName: "Nom de la Villa/Yacht",
                    selectService: "Sélectionnez le Type de Service",
                    villaService: "Service en Villa",
                    yachtService: "Service Yacht",
                    eventService: "Événement Spécial",
                    picnicService: "Pique-nique Plage",
                    classService: "Cours de Cuisine",
                    arrivalDate: "JJ/MM/AAAA",
                    guests: "Nombre de Convives",
                    message: "Parlez-nous de l'expérience souhaitée, des régimes alimentaires ou demandes spéciales",
                    send: "Envoyer la Demande",
                    directContact: "Contact Direct",
                    availableHours: "Heures d'Ouverture",
                    availableHoursText: "Disponible 7j/7 - 24h/24",
                    flexibleScheduling: "Horaires flexibles selon vos besoins",
                    footerTagline: "Service de Chef Privé de Luxe à St Barth",
                    chefTableTitle: "Table du Chef",
                    chefTableDesc: "Expérience gastronomique exclusive avec menu dégustation personnalisé",
                    beachDiningTitle: "Dîner sur la Plage",
                    beachDiningDesc: "Expérience culinaire unique au coucher du soleil sur votre plage privée",
                    seaEscapeTitle: "Escapade en Mer & Gastronomie",
                    seaEscapeLead: "Vivez l'expérience ultime : Un dîner au coucher du soleil à bord d'un magnifique catamaran Lagoon 55",
                    seaEscapeSunset: "Naviguez au coucher du soleil dans les eaux cristallines de St Barth",
                    seaEscapeService: "Private chef and hostess at your service",
                    seaEscapeLocation: "Anchor in the most exclusive cove of the island",
                    seaEscapeDining: "Gourmet dining under the stars",
                    seaEscapeBook: "Book Your Sea Escape"
                }
            },
            it: {
                translation: {
                    services: "Servizi",
                    menus: "Menu",
                    specialties: "Specialità",
                    contact: "Contatto",
                    heroTitle: "Esperienza Esclusiva con Chef Privato",
                    heroSubtitle: "Alta cucina nella tua villa a St Barth",
                    bookNow: "Prenota la tua esperienza",
                    servicesTitle: "I Nostri Servizi",
                    villaExperience: "Servizio in Villa",
                    villaExperienceDesc: "Servizio completo di chef privato nella tua villa, dalla colazione alla cena",
                    yachtService: "Servizio Yacht",
                    yachtServiceDesc: "Servizio chef esclusivo a bordo del tuo yacht, incluso approvvigionamento",
                    provisioning: "Approvvigionamento",
                    provisioningDesc: "Approvvigionamento di ville e yachts con prodotti locali premium",
                    eventsService: "Eventi & Cocktail",
                    eventsServiceDesc: "Cocktail di lusso e catering per eventi speciali",
                    cookingClasses: "Lezioni di Cucina",
                    cookingClassesDesc: "Lezioni private di cucina caraibica e francese",
                    beachPicnics: "Picnic in Spiaggia",
                    beachPicnicsDesc: "Esperienze picnic di lusso a Colombier o Shell Beach",
                    testimonialsTitle: "Testimonianze",
                    testimonial1: "\"Servizio eccezionale. Lo chef ha creato una cena indimenticabile sul nostro yacht con incredibili ingredienti locali.\"",
                    client1: "- Jean-Pierre M., Parigi",
                    testimonial2: "\"Il picnic sulla spiaggia di Colombier è stato assolutamente magico. Ogni dettaglio era perfetto, dallo champagne all'aragosta fresca.\"",
                    client2: "- Elizabeth R., New York",
                    testimonial3: "\"Una villa completamente rifornita all'arrivo e uno chef privato per la settimana hanno reso la nostra vacanza a St Barth veramente lussuosa.\"",
                    client3: "- Michael S., Londra",
                    aboutTitle: "Il Vostro Chef",
                    aboutLead: "Anni di esperienza culinaria dai ristoranti rinomati di tutto il mondo ora nella vostra villa a St Barth",
                    aboutText: "La nostra passione per la cucina eccezionale combinata con un servizio personalizzato garantisce esperienze gastronomiche indimenticabili nel comfort del vostro alloggio",
                    menusTitle: "I Nostri Menu",
                    breakfast: "Colazione",
                    lunch: "Pranzo",
                    dinner: "Cena",
                    continentalTitle: "Colazione Continentale",
                    continentalDesc: "Pasticceria fresca, frutta tropicale, yogurt con muesli",
                    healthyTitle: "Colazione Salutare",
                    healthyDesc: "Bowl di açai, toast all'avocado, smoothies frais",
                    mediterraneanTitle: "Pranzo Mediterraneo",
                    mediterraneanDesc: "Poisson frais, légumes grillés, salade de quinoa",
                    gourmetToGoTitle: "Gourmet da Asporto",
                    gourmetToGoDesc: "Boîtes pique-nique luxe pour la plage",
                    caribbeanSeafoodTitle: "Frutti di Mare Caraibici",
                    caribbeanSeafoodDesc: "Langouste locale, ceviche, saveurs insulaires fraîches",
                    lightFreshTitle: "Leggero & Fresco",
                    lightFreshDesc: "Salades colorées, grillades, jus pressés",
                    signatureTitle: "Cena Signature",
                    signatureDesc: "Menu dégustation di sette portate con abbinamento vini",
                    oceanTableTitle: "Dal Mare alla Tavola",
                    oceanTableDesc: "Fruits de mer frais selon votre choix",
                    caribbeanLuxuryTitle: "Lusso Caraibico",
                    caribbeanLuxuryDesc: "Grilled lobster, truffle risotto, champagne sauce",
                    sunsetBBQTitle: "BBQ al Tramonto",
                    sunsetBBQDesc: "Specialità alla griglia con vista mozzafiato sull'oceano",
                    specialtiesTitle: "Le Nostre Specialità",
                    detoxTitle: "Detox & Benessere",
                    detoxDesc: "Programmi nutrizionali personalizzati, jus détox, menu benessere",
                    vipBreakfastTitle: "Colazione VIP",
                    vipBreakfastDesc: "Colazione di lusso in terrazza con vista mare",
                    bbqTitle: "BBQ & Plancha",
                    bbqDesc: "Grigliate di lusso sul mare: langouste, wagyu, pesci pregiati",
                    celebrationsTitle: "Célébrations",
                    celebrationsDesc: "Compleanni, proposte di matrimonio, eventi privati",
                    welcomePackTitle: "Pacchetto di Benvenuto",
                    welcomePackDesc: "Champagne, fresh fruits, amuse-bouches gourmets à l'arrivo",
                    photographyTitle: "Photographie Culinaria",
                    photographyDesc: "Séance photo de vos expériences gastronomiques",
                    marketTourTitle: "Tour del Mercato",
                    marketTourDesc: "Visita guidata ai migliori produttori locali",
                    giftCardsTitle: "Carte Regalo",
                    giftCardsDesc: "Regalate un'esperienza gastronomica unica",
                    galleryTitle: "Le Nostre Creazioni",
                    eventsTitle: "Événements Spéciaux et Partenariats",
                    holidayTitle: "Saison des Fêtes",
                    holidayDesc: "Dîners exclusifs de Noël et Nouvel An avec menus spéciaux",
                    bucketTitle: "Bucket Regatta",
                    bucketDesc: "Service traiteur spécialisé pour les équipages et invités pendant la prestigieuse régate",
                    rumTitle: "Dégustation de Rhum",
                    rumDesc: "Dégustation de rhums caraïbes avec amuse-bouches raffinés",
                    villaAgencyTitle: "Agences de Villas",
                    villaAgencyDesc: "Partenaire privilégié des principales agences de location de villas de luxe à St Barth",
                    conciergeTitle: "Service Conciergerie",
                    conciergeDesc: "Collaboration parfaite avec les meilleurs services de conciergerie de l'île",
                    yachtBrokerTitle: "Courtiers Yacht",
                    yachtBrokerDesc: "Partenaire culinaire de confiance des principales sociétés de charter",
                    dietaryTitle: "Esigenze Dietetiche Speciali",
                    dietaryLead: "Siamo orgogliosi di accogliere tutte le preferenze e i requisiti alimentari, garantendo un'esperienza culinaria eccezionale per ogni ospite",
                    vegetarianTitle: "Menu Vegetariano e Vegano",
                    vegetarianDesc: "Piatti vegetali creativi con prodotti locali freschi e tecniche di cucina innovative",
                    glutenFreeTitle: "Senza Glutine",
                    glutenFreeDesc: "Deliziose alternative senza glutine senza compromessi su gusto e presentazione",
                    kosherTitle: "Menu Kosher",
                    kosherDesc: "Preparazione rispettosa dei piatti kosher secondo tutti i requisiti tradizionali",
                    allergyTitle: "Allergie Alimentari",
                    allergyDesc: "Particolare attenzione alle allergie e prevenzione delle contaminazioni incrociate",
                    dietaryNote: "Vi preghiamo di informarci di eventuali esigenze dietetiche o allergie al momento della prenotazione. Adatteremo volentieri i nostri menu alle vostre esigenze",
                    contactTitle: "Prenota Ora",
                    contactLead: "Contattaci per creare la tua esperienza culinaria perfetta a St Barth",
                    name: "Il Tuo Nome",
                    email: "La Tua Email",
                    phone: "Telefono/WhatsApp",
                    villaName: "Nome Villa/Yacht",
                    selectService: "Seleziona Tipo di Servizio",
                    villaService: "Servizio in Villa",
                    yachtService: "Servizio Yacht",
                    eventService: "Evento Speciale",
                    picnicService: "Picnic in Spiaggia",
                    classService: "Corso di Cucina",
                    arrivalDate: "GG/MM/AAAA",
                    guests: "Numero di Ospiti",
                    message: "Raccontaci l'esperienza desiderata, requisiti dietetici o richieste speciali",
                    send: "Invia Richiesta",
                    directContact: "Contact Direct",
                    availableHours: "Heures d'Ouverture",
                    availableHoursText: "Disponible 7j/7 - 24h/24",
                    flexibleScheduling: "Horaires flexibles selon vos besoins",
                    footerTagline: "Service de Chef Privé de Luxe à St Barth",
                    chefTableTitle: "Tavolo dello Chef",
                    chefTableDesc: "Esperienza gastronomica esclusiva con menu degustazione personalizzato",
                    beachDiningTitle: "Cena in Spiaggia",
                    beachDiningDesc: "Esperienza culinaria unica al tramonto sulla tua spiaggia privata",
                    seaEscapeTitle: "Sea & Gastronomy Escape",
                    seaEscapeLead: "Experience the ultimate luxury: A sunset dinner cruise aboard a magnificent Lagoon 55 catamaran",
                    seaEscapeSunset: "Sail into the sunset in the pristine waters of St Barth",
                    seaEscapeService: "Private chef and hostess at your service",
                    seaEscapeLocation: "Anchor in the most exclusive cove of the island",
                    seaEscapeDining: "Gourmet dining under the stars",
                    seaEscapeBook: "Book Your Sea Escape"
                }
            },
            es: {
                translation: {
                    services: "Servicios",
                    menus: "Menús",
                    specialties: "Especialidades",
                    contact: "Contacto",
                    heroTitle: "Experiencia Exclusiva de Chef Privado",
                    heroSubtitle: "Alta cocina en tu villa en St Barth",
                    bookNow: "Reserva tu experiencia",
                    servicesTitle: "Nuestros Servicios",
                    villaExperience: "Servicio en Villa",
                    villaExperienceDesc: "Servicio completo de chef privado en tu villa, desde el desayuno hasta la cena",
                    yachtService: "Servicio de Yate",
                    yachtServiceDesc: "Servicio exclusivo de chef a bordo de tu yate, incluido aprovisionamiento",
                    provisioning: "Aprovisionamiento",
                    provisioningDesc: "Aprovisionamiento de villas y yates con productos locales premium",
                    eventsService: "Eventos y Cócteles",
                    eventsServiceDesc: "Cócteles de lujo y catering para eventos especiales",
                    cookingClasses: "Clases de Cocina",
                    cookingClassesDesc: "Clases privadas de cocina caribeña y francesa",
                    beachPicnics: "Picnics en la Playa",
                    beachPicnicsDesc: "Experiencias de picnic de lujo en Colombier o Shell Beach",
                    testimonialsTitle: "Testimonios",
                    testimonial1: "\"Servicio excepcional. El chef creó una cena inolvidable en nuestro yate con increíbles ingredientes locales.\"",
                    client1: "- Jean-Pierre M., París",
                    testimonial2: "\"El picnic en la playa de Colombier fue absolutamente mágico. Cada detalle fue perfecto, desde el champán hasta la langosta fresca.\"",
                    client2: "- Elizabeth R., Nueva York",
                    testimonial3: "\"Una villa completamente abastecida a la llegada y un chef privado durante la semana hicieron que nuestras vacaciones en St Barth fueran verdaderamente lujosas.\"",
                    client3: "- Michael S., Londres",
                    aboutTitle: "Su Chef",
                    aboutLead: "Años de experiencia culinaria de restaurantes reconocidos en todo el mundo ahora en su villa en St Barth",
                    aboutText: "Nuestra pasión por la cocina eccezionale combinada con un servicio personalizado garantisce esperienze gastronómicas inolvidables nel comfort del vostro alloggio",
                    menusTitle: "Nuestros Menús",
                    breakfast: "Desayuno",
                    lunch: "Almuerzo",
                    dinner: "Cena",
                    continentalTitle: "Desayuno Continental",
                    continentalDesc: "Pastelería fresca, frutas tropicales, yogur con muesli",
                    healthyTitle: "Desayuno Saludable",
                    healthyDesc: "Bowl de açai, toast all'avocado, smoothies frescos",
                    mediterraneanTitle: "Almuerzo Mediterráneo",
                    mediterraneanDesc: "Pescado fresco, verduras a la parrilla, ensalada de quinoa",
                    gourmetToGoTitle: "Gourmet Para Llevar",
                    gourmetToGoDesc: "Boîtes pique-nique luxe para la playa",
                    caribbeanSeafoodTitle: "Mariscos del Caribe",
                    caribbeanSeafoodDesc: "Langosta local, ceviche, sabores frescos de la isla",
                    lightFreshTitle: "Ligero & Fresco",
                    lightFreshDesc: "Ensaladas coloridas, platos a la parrilla, zumos naturales",
                    signatureTitle: "Cena Signature",
                    signatureDesc: "Menú degustación de siete platos con maridaje de vinos",
                    oceanTableTitle: "Del Océano a la Mesa",
                    oceanTableDesc: "Fruits de mer frais selon votre choix",
                    caribbeanLuxuryTitle: "Lujo Caribeño",
                    caribbeanLuxuryDesc: "Langosta a la parrilla, risotto de trufa, salsa de champán",
                    sunsetBBQTitle: "BBQ al Atardecer",
                    sunsetBBQDesc: "Especialidades a la parrilla con impresionantes vistas al océano",
                    specialtiesTitle: "Nuestras Especialidades",
                    detoxTitle: "Detox & Bienestar",
                    detoxDesc: "Programas nutricionales personalizados, zumos detox, menús wellness",
                    vipBreakfastTitle: "Desayuno VIP",
                    vipBreakfastDesc: "Desayuno de lujo en la terraza con vistas al mar",
                    bbqTitle: "BBQ & Plancha",
                    bbqDesc: "Parrilladas de lujo junto al mar: langosta, wagyu, pescados premium",
                    celebrationsTitle: "Celebraciones",
                    celebrationsDesc: "Cumpleaños, propuestas de matrimonio, eventos privados",
                    welcomePackTitle: "Pack de Bienvenida",
                    welcomePackDesc: "Champán, frutas frescas, aperitivos gourmet a la llegada",
                    photographyTitle: "Fotografía Culinaria",
                    photographyDesc: "Sesión fotográfica de sus experiencias gastronómicas",
                    marketTourTitle: "Tour del Mercado",
                    marketTourDesc: "Visita guiada a los mejores productores locales",
                    giftCardsTitle: "Tarjetas Regalo",
                    giftCardsDesc: "Regalate una experiencia gastronómica unica",
                    galleryTitle: "Nuestras Creaciones",
                    chefTableTitle: "Mesa del Chef",
                    chefTableDesc: "Esperienza gastronomica esclusiva con menu degustazione personalizzato",
                    beachDiningTitle: "Cena en la Playa",
                    beachDiningDesc: "Esperienza culinaria única al atardecer en su playa privada",
                    seaEscapeTitle: "Sea & Gastronomy Escape",
                    seaEscapeLead: "Experience the ultimate luxury: A sunset dinner cruise aboard a magnificent Lagoon 55 catamaran",
                    seaEscapeSunset: "Sail into the sunset in the pristine waters of St Barth",
                    seaEscapeService: "Private chef and hostess at your service",
                    seaEscapeLocation: "Anchor in the most exclusive cove of the island",
                    seaEscapeDining: "Gourmet dining under the stars",
                    seaEscapeBook: "Book Your Sea Escape"
                }
            },
            de: {
                translation: {
                    services: "Dienstleistungen",
                    menus: "Menüs",
                    specialties: "Spezialitäten",
                    contact: "Kontakt",
                    heroTitle: "Exklusive Privatkoch-Erfahrung",
                    heroSubtitle: "Gehobene Küche in Ihrer Villa in St Barth",
                    bookNow: "Buchen Sie Ihr Erlebnis",
                    servicesTitle: "Unsere Dienstleistungen",
                    villaExperience: "Villa-Service",
                    villaExperienceDesc: "Kompletter Privatkoch-Service in Ihrer Villa, vom Frühstück bis zum Abendessen",
                    yachtService: "Yacht-Service",
                    yachtServiceDesc: "Exklusiver Koch-Service an Bord Ihrer Yacht, einschließlich Verpflegung",
                    provisioning: "Verpflegung",
                    provisioningDesc: "Verpflegung von Villen und Yachten mit hochwertigen lokalen Produkten",
                    eventsService: "Events & Cocktails",
                    eventsServiceDesc: "Luxus-Cocktails und Catering für besondere Anlässe",
                    cookingClasses: "Kochkurse",
                    cookingClassesDesc: "Private karibische und französische Kochkurse",
                    beachPicnics: "Strand-Picknicks",
                    beachPicnicsDesc: "Luxuriöse Picknick-Erlebnisse am Colombier oder Shell Beach",
                    testimonialsTitle: "Bewertungen",
                    testimonial1: "\"Außergewöhnlicher Service. Der Koch hat mit erstaunlichen lokalen Zutaten ein unvergessliches Dinner auf unserer Yacht kreiert.\"",
                    client1: "- Jean-Pierre M., Paris",
                    testimonial2: "\"Das Picknick am Colombier-Strand war absolut magisch. Jedes Detail war perfekt, vom Champagner bis zum frischen Hummer.\"",
                    client2: "- Elizabeth R., New York",
                    testimonial3: "\"Eine bei Ankunft voll ausgestattete Villa und ein Privatkoch für die Woche machten unseren St. Barth-Urlaub wirklich luxuriös.\"",
                    client3: "- Michael S., London",
                    aboutTitle: "Ihr Koch",
                    aboutLead: "Jahre kulinarischer Erfahrung aus renommierten Restaurants weltweit jetzt in Ihrer Villa in St. Barth",
                    aboutText: "Unsere Leidenschaft für außergewöhnliche Küche kombiniert mit persönlichem Service garantiert unvergessliche gastronomische Erlebnisse im Komfort Ihrer Unterkunft",
                    menusTitle: "Unsere Menüs",
                    breakfast: "Frühstück",
                    lunch: "Mittagessen",
                    dinner: "Abendessen",
                    continentalTitle: "Kontinentales Frühstück",
                    continentalDesc: "Frische Backwaren, tropische Früchte, Joghurt mit Müsli",
                    healthyTitle: "Gesundes Frühstück",
                    healthyDesc: "Açai-Bowl, Avocado-Toast, frische Smoothies",
                    mediterraneanTitle: "Mediterranes Mittagessen",
                    mediterraneanDesc: "Frischer Fisch, gegrilltes Gemüse, Quinoa-Salat",
                    gourmetToGoTitle: "Gourmet zum Mitnehmen",
                    gourmetToGoDesc: "Luxuriöse Picknick-Boxen für den Strand",
                    caribbeanSeafoodTitle: "Karibische Meeresfrüchte",
                    caribbeanSeafoodDesc: "Lokaler Hummer, Ceviche, frische Inselaromen",
                    lightFreshTitle: "Leicht & Frisch",
                    lightFreshDesc: "Bunte Salate, Gegrilltes, frisch gepresste Säfte",
                    signatureTitle: "Signature-Dinner",
                    signatureDesc: "Sieben-Gänge-Degustationsmenü mit Weinbegleitung",
                    oceanTableTitle: "Vom Ozean auf den Tisch",
                    oceanTableDesc: "Frische Meeresfrüchte nach Ihrer Wahl",
                    caribbeanLuxuryTitle: "Karibischer Luxus",
                    caribbeanLuxuryDesc: "Gegrillter Hummer, Trüffelrisotto, Champagnersauce",
                    sunsetBBQTitle: "Sonnenuntergangs-BBQ",
                    sunsetBBQDesc: "Grillspezialitäten mit atemberaubendem Meerblick",
                    specialtiesTitle: "Unsere Spezialitäten",
                    detoxTitle: "Detox & Wellness",
                    detoxDesc: "Personalisierte Ernährungsprogramme, Detox-Säfte, Wellness-Menüs",
                    vipBreakfastTitle: "VIP-Frühstück",
                    vipBreakfastDesc: "Luxuriöses Frühstück auf der Terrasse mit Meerblick",
                    bbqTitle: "BBQ & Plancha",
                    bbqDesc: "Luxuriöses Grillen am Meer: Hummer, Wagyu, Premium-Fisch",
                    celebrationsTitle: "Feierlichkeiten",
                    celebrationsDesc: "Geburtstage, Heiratsanträge, private Veranstaltungen",
                    welcomePackTitle: "Willkommenspaket",
                    welcomePackDesc: "Champán, frische Früchte, Gourmet-Snacks bei Ankunft",
                    photographyTitle: "Food-Fotografie",
                    photographyDesc: "Fotoshooting Ihrer kulinarischen Erlebnisse",
                    marketTourTitle: "Markttour",
                    marketTourDesc: "Geführte Tour zu den besten lokalen Produzenten",
                    giftCardsTitle: "Geschenkkarten",
                    giftCardsDesc: "Verschenken Sie ein einzigartiges Gourmet-Erlebnis",
                    galleryTitle: "Unsere Tворения",
                    chefTableTitle: "Table du Chef",
                    chefTableDesc: "Expérience gastronomique exclusive avec menu dégustation personnalisé",
                    beachDiningTitle: "Dîner sur la Plage",
                    beachDiningDesc: "Expérience culinaire unique au coucher du soleil sur votre plage privée",
                    seaEscapeTitle: "Sea & Gastronomy Escape",
                    seaEscapeLead: "Experience the ultimate luxury: A sunset dinner cruise aboard a magnificent Lagoon 55 catamaran",
                    seaEscapeSunset: "Sail into the sunset in the pristine waters of St Barth",
                    seaEscapeService: "Private chef and hostess at your service",
                    seaEscapeLocation: "Anchor in the most exclusive cove of the island",
                    seaEscapeDining: "Gourmet dining under the stars",
                    seaEscapeBook: "Book Your Sea Escape"
                }
            },
            ru: {
                translation: {
                    services: "Услуги",
                    menus: "Меню",
                    specialties: "Специалитеты",
                    contact: "Контакты",
                    heroTitle: "Эксклюзивный Личный Шеф-повар",
                    heroSubtitle: "Изысканная кухня в вашей вилле на Сен-Барт",
                    bookNow: "Забронировать",
                    servicesTitle: "Наши Услуги",
                    villaExperience: "Обслуживание Виллы",
                    villaExperienceDesc: "Полный сервис личного шеф-повара в вашей вилле, от завтрака до ужина",
                    yachtService: "Обслуживание Яхты",
                    yachtServiceDesc: "Эксклюзивный сервис шеф-повара на борту вашей яхты, включая провизию",
                    provisioning: "Снабжение",
                    provisioningDesc: "Снабжение вилл и яхт премиальными местными продуктами",
                    eventsService: "Мероприятия и Коктейли",
                    eventsServiceDesc: "Элитные коктейли и кейтеринг для особых случаев",
                    cookingClasses: "Кулинарные Мастер-классы",
                    cookingClassesDesc: "Частные уроки карибской и французской кухни",
                    beachPicnics: "Пикники на Пляже",
                    beachPicnicsDesc: "Роскошные пикники на пляжах Коломбье или Шелл-Бич",
                    aboutTitle: "О Вашем Шеф-поваре",
                    aboutLead: "Многолетний кулинарный опыт из известных ресторанов по всему миру теперь в вашей вилле на Сен-Барт",
                    aboutText: "Наша страсть к исключительной кухне в сочетании с персонализированным сервисом гарантирует незабываемые гастрономические впечатления в комфорте вашего жилья",
                    menusTitle: "Наши Меню",
                    breakfast: "Завтрак",
                    lunch: "Обед",
                    dinner: "Ужин",
                    continentalTitle: "Континентальный Завтрак",
                    continentalDesc: "Свежая выпечка, тропические фрукты, йогурт с мюсли",
                    healthyTitle: "Здоровый Завтрак",
                    healthyDesc: "Боул с асаи, тост с авокадо, свежие смузи",
                    mediterraneanTitle: "Средиземноморский Обед",
                    mediterraneanDesc: "Свежая рыба, овощи на гриле, салат с киноа",
                    gourmetToGoTitle: "Гурмэ с Собой",
                    gourmetToGoDesc: "Роскошные пикник-боксы для пляжа",
                    caribbeanSeafoodTitle: "Карибские Морепродукты",
                    caribbeanSeafoodDesc: "Местный лобстер, севиче, свежие островные вкусы",
                    lightFreshTitle: "Легкие Блюда",
                    lightFreshDesc: "Красочные салаты, гриль, свежевыжатые соки",
                    signatureTitle: "Фирменный Ужин",
                    signatureDesc: "Дегустационное меню из семи блюд с вином",
                    oceanTableTitle: "Из Океана на Стол",
                    oceanTableDesc: "Свежие морепродукты по вашему выбору",
                    caribbeanLuxuryTitle: "Карибская Роскошь",
                    caribbeanLuxuryDesc: "Лобстер на гриле, ризотто с трюфелем, соус с шампанским",
                    sunsetBBQTitle: "Закатное Барбекю",
                    sunsetBBQDesc: "Блюда на гриле с потрясающими видами на океан",
                    specialtiesTitle: "Кулинарные Специалитеты",
                    detoxTitle: "Детокс и Велнес",
                    detoxDesc: "Индивидуальные программы питания, детокс-соки, велнес-меню",
                    vipBreakfastTitle: "VIP Завтрак",
                    vipBreakfastDesc: "Роскошный завтрак на террасе с видом на море",
                    bbqTitle: "Барбекю и Планча",
                    bbqDesc: "Роскошный гриль у моря: лобстер, вагю, премиальная рыба",
                    celebrationsTitle: "Торжества",
                    celebrationsDesc: "Дни рождения, предложения руки и сердца, частные мероприятия",
                    welcomePackTitle: "Приветственный Набор",
                    welcomePackDesc: "Шампанское, свежие фрукты, деликатесные закуски по прибытии",
                    photographyTitle: "Фуд-фотография",
                    photographyDesc: "Фотосессия ваших гастрономических впечатлений",
                    marketTourTitle: "Тур по Рынку",
                    marketTourDesc: "Экскурсия к лучшим местным производителям",
                    giftCardsTitle: "Подарочные Карты",
                    giftCardsDesc: "Подарите уникальный гастрономический опыт",
                    galleryTitle: "Наши Творения",
                    testimonialsTitle: "Отзывы Гостей",
                    testimonial1: "\"Исключительный сервис. Шеф создал незабываемый ужин на нашей яхте с потрясающими местными продуктами.\"",
                    client1: "- Жан-Пьер М., Париж",
                    testimonial2: "\"Пикник на пляже Коломбье был абсолютно волшебным. Каждая деталь была идеальной, от шампанского до свежего лобстера.\"",
                    client2: "- Элизабет Р., Нью-Йорк",
                    testimonial3: "\"Полностью укомплектованная вилла по прибытии и личный шеф на неделю сделали наш отдых на Сен-Барт по-настоящему роскошным.\"",
                    client3: "- Майкл С., Лондон",
                    eventsTitle: "Особые События и Партнерства",
                    holidayTitle: "Праздничный Сезон",
                    holidayDesc: "Эксклюзивные рождественские и новогодние ужины со специальным праздничным меню",
                    bucketTitle: "Bucket Regatta",
                    bucketDesc: "Специализированный кейтеринг для экипажей и гостей во время престижной парусной регаты",
                    rumTitle: "Дегустация Рома",
                    rumDesc: "Дегустация карибского рома с изысканными закусками",
                    villaAgencyTitle: "Агентства Вилл",
                    villaAgencyDesc: "Предпочтительный партнер ведущих агентств по аренде люксовых вилл на Сен-Барт",
                    conciergeTitle: "Консьерж-сервис",
                    conciergeDesc: "Безупречное сотрудничество с лучшими консьерж-службами острова",
                    yachtBrokerTitle: "Яхт-брокеры",
                    yachtBrokerDesc: "Надежный кулинарный партнер ведущих чартерных компаний",
                    dietaryTitle: "Особые Диетические Требования",
                    dietaryLead: "Мы гордимся тем, что учитываем все диетические предпочтения и требования, гарантируя каждому гостю исключительный кулинарный опыт",
                    vegetarianTitle: "Вегетарианское и Веганское Меню",
                    vegetarianDesc: "Креативные растительные блюда из свежих местных продуктов с инновационными техниками приготовления",
                    glutenFreeTitle: "Без Глютена",
                    glutenFreeDesc: "Вкусные безглютеновые альтернативы без компромиссов во вкусе и подаче",
                    kosherTitle: "Кошерное Меню",
                    kosherDesc: "Уважительное приготовление кошерных блюд с соблюдением всех традиционных требований",
                    allergyTitle: "Пищевые Аллергии",
                    allergyDesc: "Особое внимание к аллергиям и предотвращение перекрестного загрязнения при приготовлении",
                    dietaryNote: "Пожалуйста, сообщите нам о любых диетических требованиях или аллергиях при бронировании. Мы с радостью адаптируем наши меню под ваши потребности",
                    contactTitle: "Забронировать",
                    contactLead: "Свяжитесь с нами, чтобы создать ваш идеальный кулинарный опыт на Сен-Барт",
                    name: "Ваше Имя",
                    email: "Ваш Email",
                    phone: "Телефон/WhatsApp",
                    villaName: "Название Виллы/Яхты",
                    selectService: "Выберите Тип Услуги",
                    villaService: "Обслуживание Виллы",
                    yachtService: "Обслуживание Яхты",
                    eventService: "Особое Мероприятие",
                    picnicService: "Пикник на Пляже",
                    classService: "Кулинарный Мастер-класс",
                    arrivalDate: "Дата Прибытия",
                    guests: "Количество Гостей",
                    message: "Расскажите о желаемом опыте, диетических требованиях или особых пожеланиях",
                    send: "Отправить Запрос",
                    directContact: "Прямой Контакт",
                    availableHours: "Часы Работы",
                    availableHoursText: "Доступны 7/7 - 24/24",
                    flexibleScheduling: "Гибкий график под ваши потребности",
                    footerTagline: "Услуги частного шеф-повара класса люкс на Сен-Барт",
                    chefTableTitle: "Table du Chef",
                    chefTableDesc: "Expérience gastronomique exclusive avec menu dégustation personnalisé",
                    beachDiningTitle: "Dîner sur la Plage",
                    beachDiningDesc: "Expérience culinaire уникальная на закате солнца на вашей приватной пляже",
                    seaEscapeTitle: "Sea & Gastronomy Escape",
                    seaEscapeLead: "Experience the ultimate luxury: A sunset dinner cruise aboard a magnificent Lagoon 55 catamaran",
                    seaEscapeSunset: "Sail into the sunset in the pristine waters of St Barth",
                    seaEscapeService: "Private chef and hostess at your service",
                    seaEscapeLocation: "Anchor in the most exclusive cove of the island",
                    seaEscapeDining: "Gourmet dining under the stars",
                    seaEscapeBook: "Book Your Sea Escape"
                }
            }
        }
    });

// Handle language switching
document.querySelectorAll('[data-lang]').forEach(element => {
    element.addEventListener('click', (e) => {
        e.preventDefault();
        const lang = e.target.closest('[data-lang]').getAttribute('data-lang');
        i18next.changeLanguage(lang).then(() => {
            updateContent();
        });
    });
});

// Update content when language changes
function updateContent() {
    document.querySelectorAll('[data-i18n]').forEach(element => {
        const key = element.getAttribute('data-i18n');
        const placeholderKey = element.getAttribute('data-i18n-placeholder');
        
        if (key.startsWith('[placeholder]')) {
            // Handle placeholder attributes
            const cleanKey = key.replace('[placeholder]', '');
            element.placeholder = i18next.t(cleanKey);
        } else if (element.tagName === 'SELECT') {
            // Handle select elements
            element.querySelectorAll('option').forEach(option => {
                if (option.hasAttribute('data-i18n')) {
                    option.textContent = i18next.t(option.getAttribute('data-i18n'));
                }
            });
        } else {
            // Handle regular text content
            element.textContent = i18next.t(key);
        }
    });
}

// Change navbar background on scroll
window.addEventListener('scroll', () => {
    const navbar = document.querySelector('.navbar');
    if (window.scrollY > 50) {
        navbar.style.backgroundColor = 'rgba(26, 26, 26, 0.95)';
    } else {
        navbar.style.backgroundColor = 'rgba(26, 26, 26, 0.9)';
    }
});

// Mettre à jour le contenu initial
document.addEventListener('DOMContentLoaded', () => {
    updateContent();
});

// Ajouter la gestion du formulaire
document.getElementById('contact-form').addEventListener('submit', async (e) => {
    e.preventDefault(); // Empêche la soumission par défaut du formulaire
    
    const form = e.target;
    const formData = {
        name: form.querySelector('[data-i18n="[placeholder]name"]').value,
        email: form.querySelector('[data-i18n="[placeholder]email"]').value,
        phone: form.querySelector('[data-i18n="[placeholder]phone"]').value,
        villaName: form.querySelector('[data-i18n="[placeholder]villaName"]').value,
        service: form.querySelector('select').value,
        arrivalDate: form.querySelector('[data-i18n="[placeholder]arrivalDate"]').value,
        guests: form.querySelector('[data-i18n="[placeholder]guests"]').value,
        message: form.querySelector('textarea').value
    };

    try {
        const submitButton = form.querySelector('button[type="submit"]');
        submitButton.disabled = true;

        const result = await sendContactEmail(formData);
        
        if (result.success) {
            // Tracking du succès de l'envoi du formulaire
            gtag('event', 'form_submission', {
                'event_category': 'Contact',
                'event_label': 'Success'
            });
            form.reset();
        }

        submitButton.disabled = false;
    } catch (error) {
        // Tracking de l'échec de l'envoi du formulaire
        gtag('event', 'form_submission', {
            'event_category': 'Contact',
            'event_label': 'Error'
        });
        console.error('Error:', error);
    }
});

// Mise à jour dynamique des balises meta selon la langue
function updateMetaTags(lang) {
    const metaDescription = document.querySelector('meta[name="description"]');
    const ogTitle = document.querySelector('meta[property="og:title"]');
    const ogDescription = document.querySelector('meta[property="og:description"]');
    
    document.documentElement.lang = lang;
    
    if (lang === 'fr') {
        document.title = "The Gourmet St Barth - Chef Privé de Luxe à Saint-Barthélemy";
        metaDescription.content = "Service de chef privé de luxe à St Barth...";
        // ... autres mises à jour
    } else if (lang === 'en') {
        document.title = "The Gourmet St Barth - Luxury Private Chef in St Barts";
        metaDescription.content = "Luxury private chef service in St Barts...";
        // ... autres mises à jour
    }
    // ... autres langues
}

// Mettre à jour les meta tags lors du changement de langue
i18next.on('languageChanged', (lang) => {
    updateMetaTags(lang);
    updateContent();
    gtag('event', 'language_change', {
        'event_category': 'Localization',
        'event_label': lang
    });
});

document.querySelectorAll('.btn-primary').forEach(button => {
    button.addEventListener('click', () => {
        gtag('event', 'click', {
            'event_category': 'CTA',
            'event_label': button.textContent
        });
    });
});

document.querySelectorAll('.nav-link').forEach(link => {
    link.addEventListener('click', () => {
        gtag('event', 'menu_navigation', {
            'event_category': 'Navigation',
            'event_label': link.textContent
        });
    });
});

// Tracking du temps passé sur les sections
const sectionObserver = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            gtag('event', 'section_view', {
                'event_category': 'Engagement',
                'event_label': entry.target.id,
                'non_interaction': true
            });
        }
    });
}, {
    threshold: 0.5 // Déclenche l'événement quand 50% de la section est visible
});

document.querySelectorAll('section[id]').forEach(section => {
    sectionObserver.observe(section);
}); 