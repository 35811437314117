export const sendContactEmail = async (formData) => {
    try {
        const response = await fetch('http://localhost:3000/api/send-email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData)
        });

        const data = await response.json();
        
        if (data.success) {
            // Utilisation de SweetAlert2 pour une notification plus moderne
            Swal.fire({
                icon: 'success',
                title: 'Thank you!',
                text: 'Your message has been sent successfully. We will contact you shortly.',
                confirmButtonColor: '#c8a97e',
                timer: 3000
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong. Please try again later.',
                confirmButtonColor: '#c8a97e'
            });
        }
        
        return data;
    } catch (error) {
        console.error('Error:', error);
        Swal.fire({
            icon: 'error',
            title: 'Connection Error',
            text: 'Unable to send your message. Please check your internet connection.',
            confirmButtonColor: '#c8a97e'
        });
        return { success: false, error: error.message };
    }
}; 